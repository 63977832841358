import {
    Container, Menu, MenuItem, Button, Drawer, List, ListItem, ListItemButton, ListItemText, Box
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import {useState} from "react";
import {DarkModeToggle} from "./components/DarkModeToggle";
import CloseIcon from '@mui/icons-material/Close';
import { useMatchMedia } from "./hooks/useMatchMedia";



const NavBar = () => {
  const isDesktopResolution = useMatchMedia("(min-width:600px)", true);
  const [isMenuOpen, setMenuOpen] = useState(false);

  console.log("isDesktopResolution " + isDesktopResolution)
    const routeChange = (path) => {
      window.location.href = path
    }

  return (
    <Container maxWidth className="navbar">

      <nav>
      {isDesktopResolution &&
        (<ul>

          <li><a href="/">Jared Welch</a></li>
          {/* <li><a href="/blog">Blog</a></li> */}
          {/*<li><a href="/newsletter">Newsletter</a></li>*/}
          {/*<li><a href="/podcast">Podcast</a></li>*/}
          {/* <li><a href="/tools">Tools</a></li>
          <li><a href="https://courses.jaredbwelch.com">Learn Python</a></li> */}
          {/*<li><a href="/guides">Learning</a></li>*/}
          {/*<li><a href="/cheatsheets">Cheat Sheets</a></li>*/}

          <li><DarkModeToggle/></li>
          {/*<li><GoogleLogin onSuccess={()=>console.log("woo")}/></li>*/}
        </ul>)}

        {!isDesktopResolution &&
        (<Container fullWidth sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', minHeight: '54px', padding: '0px'}}>
          <Button className="menuHamburgerButton" onClick={() => setMenuOpen(true)}>
            <MenuIcon id="menu-mobile-icon"/>
          </Button>
          <DarkModeToggle/>
          <Drawer
            anchor="left"
            open={isMenuOpen}
            onClose={() => console.log('close')}
            sx={{color: 'var(--color-font)',
                '& .MuiDrawer-paperAnchorLeft': {backgroundColor: "var(--color-background)", color: "var(--color-font)"}
            }}
          >
            <Box sx={{width: '100vw'}}>
            <List fullWidth>
              <ListItem>
                <Button sx={{color: 'var(--color-font)'}} onClick={() => setMenuOpen(false)}>
                <CloseIcon/>
                </Button>
              </ListItem>
              <ListItem>
                  <ListItemButton>
                      <ListItemText onClick={({target}) => routeChange("/")} primary="Home" />
                  </ListItemButton>
              </ListItem>
            </List>
            </Box>
          </Drawer>
        </Container>)}
      </nav>
    </Container>
  );
}

export default NavBar;